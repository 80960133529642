<template>
    <modal ref="modalNuevaPolitica" tamano="modal-lg" titulo="Nueva política" no-aceptar adicional="Guardar" @adicional="guardarPolitica">
        <ValidationObserver ref="formHija">
                <div class="row mx-0 j-center">
                    <div class="col-12 mb-1 mt-2">
                        <p class="label pl-3">
                            Nombre de la política
                        </p>
                        <ValidationProvider v-slot="{errors}" rules="required|max:80" name="nombre">
                            <el-input v-model="titulo" class="w-100" placeholder="Nombre" maxlength="80" show-word-limit size="small" />
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 mt-2 mb-2">
                        <p class="label pl-3">
                            Descripción
                        </p>
                        <ValidationProvider v-slot="{errors}" name="descripción">
                            <el-input v-model="respuesta" placeholder="Descripción"  type="textarea" :rows="25" class="w-100" size="small" />
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Configuracion from '@/services/configuracion'
export default {
    data(){
        return {
            titulo: '',
            respuesta: '',
            idPadre: null
        }
    },
    methods: {
        toggle(){
            this.$refs.formHija.reset()
            this.titulo = null
            this.respuesta = null

            this.$refs.modalNuevaPolitica.toggle();
        },
        async guardarPolitica(){
            try {
                
                let validate = await this.$refs.formHija.validate()
                if(!validate) return this.notificacion('','Campos incompletos')

                let params = {
                    tipo: 3,
                    titulo: this.titulo,
                    respuesta: this.respuesta
                }

                const { data } = await Configuracion.guardarFaq(params)

                this.notificacion('','Política creada correctamente','success')
                this.$refs.modalNuevaPolitica.toggle();
                
                this.$emit('actualizar')
            } catch (error) {
                this.error_catch(error)
            }
        },

    }
}
</script>
<style lang="scss" scoped>
.label{
    font-size: 12px;
    color: var(--text-general);
}
</style>